import spanishMessages from 'ra-language-spanish';
import englishMessages from 'ra-language-english';
import catalanMessages from 'ra-language-catalan';
import euskeraMessages from './locales/system/ra_language_euskera';
import frenchMessages from 'ra-language-french';
import italianMessages from 'ra-language-italian';
import gallegoMessages from './locales/system/ra_language_gallego';
import portugueseMessages from 'ra-language-portuguese';
import es from './locales/spanish.json';
import en from './locales/english.json';
import ca from './locales/catalan.json';
import eu from './locales/euskera.json';
import fr from './locales/french.json';
import it from './locales/italian.json';
import gl from './locales/gallego.json';
import pt from './locales/portuguese.json';

spanishMessages.ra.auth = {...spanishMessages.ra.auth, ...es.patch.auth }
spanishMessages.ra.action = {...spanishMessages.ra.action, ...es.patch.action }
spanishMessages.ra.navigation = {...spanishMessages.ra.navigation, ...es.patch.navigation }
spanishMessages.ra.message = {...spanishMessages.ra.message, ...es.patch.message }
spanishMessages.ra.boolean = {...spanishMessages.ra.boolean, ...es.patch.boolean }

englishMessages.ra.auth = {...englishMessages.ra.auth, ...en.patch.auth }
englishMessages.ra.action = {...englishMessages.ra.action, ...en.patch.action }
englishMessages.ra.navigation = {...englishMessages.ra.navigation, ...en.patch.navigation }
englishMessages.ra.message = {...englishMessages.ra.message, ...en.patch.message }
englishMessages.ra.boolean = {...englishMessages.ra.boolean, ...en.patch.boolean }

catalanMessages.ra.auth = {...catalanMessages.ra.auth, ...ca.patch.auth }
catalanMessages.ra.action = {...catalanMessages.ra.action, ...ca.patch.action }
catalanMessages.ra.navigation = {...catalanMessages.ra.navigation, ...ca.patch.navigation }
catalanMessages.ra.message = {...catalanMessages.ra.message, ...ca.patch.message }
catalanMessages.ra.boolean = {...catalanMessages.ra.boolean, ...ca.patch.boolean }

euskeraMessages.ra.auth = {...euskeraMessages.ra.auth, ...eu.patch.auth }
euskeraMessages.ra.action = {...euskeraMessages.ra.action, ...eu.patch.action }
euskeraMessages.ra.navigation = {...euskeraMessages.ra.navigation, ...eu.patch.navigation }
euskeraMessages.ra.message = {...euskeraMessages.ra.message, ...eu.patch.message }
euskeraMessages.ra.boolean = {...euskeraMessages.ra.boolean, ...eu.patch.boolean }

frenchMessages.ra.auth = {...frenchMessages.ra.auth, ...fr.patch.auth }
frenchMessages.ra.action = {...frenchMessages.ra.action, ...fr.patch.action }
frenchMessages.ra.navigation = {...frenchMessages.ra.navigation, ...fr.patch.navigation }
frenchMessages.ra.message = {...frenchMessages.ra.message, ...fr.patch.message }
frenchMessages.ra.boolean = {...frenchMessages.ra.boolean, ...fr.patch.boolean }

italianMessages.ra.auth = {...italianMessages.ra.auth, ...it.patch.auth }
italianMessages.ra.action = {...italianMessages.ra.action, ...it.patch.action }
italianMessages.ra.navigation = {...italianMessages.ra.navigation, ...it.patch.navigation }
italianMessages.ra.message = {...italianMessages.ra.message, ...it.patch.message }
italianMessages.ra.boolean = {...italianMessages.ra.boolean, ...it.patch.boolean }

gallegoMessages.ra.auth = {...gallegoMessages.ra.auth, ...gl.patch.auth }
gallegoMessages.ra.action = {...gallegoMessages.ra.action, ...gl.patch.action }
gallegoMessages.ra.navigation = {...gallegoMessages.ra.navigation, ...gl.patch.navigation }
gallegoMessages.ra.message = {...gallegoMessages.ra.message, ...gl.patch.message }
gallegoMessages.ra.boolean = {...gallegoMessages.ra.boolean, ...gl.patch.boolean }


portugueseMessages.ra.auth = {...portugueseMessages.ra.auth, ...pt.patch.auth }
portugueseMessages.ra.action = {...portugueseMessages.ra.action, ...pt.patch.action }
portugueseMessages.ra.navigation = {...portugueseMessages.ra.navigation, ...pt.patch.navigation }
portugueseMessages.ra.message = {...portugueseMessages.ra.message, ...pt.patch.message }
portugueseMessages.ra.boolean = {...portugueseMessages.ra.boolean, ...pt.patch.boolean }

export const messages = {
  es: { ...spanishMessages, ...es },
  en: { ...englishMessages, ...en },
  ca: { ...catalanMessages, ...ca },
  eu: { ...euskeraMessages, ...eu },
  fr: { ...frenchMessages, ...fr },
  it: { ...italianMessages, ...it },
  gl: { ...gallegoMessages, ...gl },
  pt: { ...portugueseMessages, ...pt },
};

export const LOCALES = ["es","en","eu","ca","fr","it","gl","pt"];

let fromBrowser = navigator.language.substring(1,3);

if (LOCALES.indexOf(fromBrowser) < 0) {
  fromBrowser = "en";
}

export const browserLocale = fromBrowser;

const muiES = {
  fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
  fieldDayPlaceholder: () => 'DD',
}
const muiEU = {
  fieldYearPlaceholder: (params) => 'U'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'HHHH' : 'HH'),
  fieldDayPlaceholder: () => 'EE',
}
const muiCA = {
  fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
  fieldDayPlaceholder: () => 'DD',
}

export const muiLocales = {
  es: muiES,
  ca: muiCA,
  en: null,
  eu: muiEU,
  fr: null,
  it: null,
  gl: null,
  pt: null
};

export const muiLocale = muiLocales[browserLocale];