/* The auth_provider knows about the current login as reported to the react app
 * before attempting to use the data provider.
 * It can create, retrieve and delete the private key from local storage.
 * It uses a special data provider for registering a new public key to the server together with authentication details.
 */
import * as React from 'react';
import { Settings } from '../Settings';
import _ from 'lodash';
import { createSessionDataProvider, defaultDataProvider, sha256sum} from "./data_provider";
import {generateKeyPair, exportSPKI, exportPKCS8 } from 'jose';


export function getAuthKeys() {
  return {
    "sessionPublicKey": localStorage.getItem("sessionPublicKey"),
    "sessionPrivateKey": localStorage.getItem("sessionPrivateKey"),
  };
}

export function setAuthKeys(object) {
  if (!_.every(['sessionPublicKey', 'sessionPrivateKey'], (k) => object[k])){
    return false;
  }
  localStorage.setItem("sessionPublicKey", object.sessionPublicKey);
  localStorage.setItem("sessionPrivateKey", object.sessionPrivateKey);
  return true;
}

function clearAuthKeys() {
  for(const k of ['sessionPublicKey', 'sessionPrivateKey']) {
    localStorage.removeItem(k);
  }
}

export function makeVidconnectUrl(clientId, vidconnectClientId) {
  const nonce = new Date().getTime();
  const { domain, redirectUriLogin } = Settings.vidconnect;
  return `${domain}/oauth2/auth?response_type=code&state=${clientId}-${nonce}&redirect_uri=${redirectUriLogin}&client_id=${vidconnectClientId}&scope=openid%20EmailCredential&nonce=${nonce}`;
}

export const makeKeys = async () => {
  const { publicKey, privateKey } = await generateKeyPair('ES256', { extractable: true });
  return {
    sessionPublicKey: (await exportSPKI(publicKey)),
    sessionPrivateKey: (await exportPKCS8(privateKey)),
  };
}

export const getPermissionsAndSetThem = async (
  {authProvider, setPermissions, setLoading} : {authProvider: any, setPermissions: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>}
  ) => {
    const result = await authProvider.getPermissions();
    setPermissions(result);
    setLoading && setLoading(false);
}


export const getSessionAndSetIt = async (
  {setSession, setLoading} : {setSession: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>}
  ) => {
    const result = await getSession();
    setSession(result);
    setLoading && setLoading(false);
}


const authProvider: any = {
  login: async ({token}) => {
    try {
      const keys = await makeKeys();
      const dataProvider = await createSessionDataProvider(keys, token);
      await dataProvider.create('Session', { data: {} });
      setAuthKeys(keys);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  checkError: (error, graphQLErrors) => {
    const graphQLFail = graphQLErrors?.[0].message === "401";
    const status = error.status || error?.networkError?.statusCode;
    const httpFail = status === 401 || status === 403

    if (graphQLFail) { return Promise.reject(graphQLErrors); }
    if (httpFail) { return Promise.reject(error); }

    return Promise.resolve();
  },
  checkAuth: () => {
    let allSet = _.every(
      ['sessionPublicKey', 'sessionPrivateKey'],
      (k) => localStorage.getItem(k)
    );
    return allSet ? Promise.resolve() : Promise.reject( { redirectTo: '/login' } )
  },
  logout: () => {
    clearAuthKeys();
    while (!!localStorage.getItem("sessionPublicKey") || !!localStorage.getItem("sessionPrivateKey")) { }
    localStorage.setItem("logout", "true");
    return Promise.resolve();
  },
  getPermissions: async () => {
    const session = await getSession();
    return session.permissions;
  },
  getLang: async () => {
    const session = await getSession();
    return session.lang;
  }
};

const getSession = async () => {
  const sessionPublicKey = getAuthKeys()["sessionPublicKey"];
  if (!sessionPublicKey) return false;
  const key = await sha256sum(sessionPublicKey);
  const dataProvider = await defaultDataProvider();
  const result = await dataProvider.getOne('Session', {id: key} );
  return result.data;
}

export default authProvider
